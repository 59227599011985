<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-06 14:08:27
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-22 14:13:08
-->
<template>
  <div class="myDiv">
    <div class="sendBox">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="deviceId" prop="deviceId">
          <el-input v-model="form.deviceId"></el-input>
        </el-form-item>
        <el-form-item label="address" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="fileUrl" prop="fileUrl">
          <el-input v-model="form.fileUrl"></el-input>
        </el-form-item>
        <el-form-item label="lng" prop="lng">
          <el-input v-model="form.lng"></el-input>
        </el-form-item>
        <el-form-item label="lat" prop="lat">
          <el-input v-model="form.lat"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn_box">
        <el-button type="primary" @click="send">发请求</el-button>
      </div>
      <div class="paramsBox" v-show="parmasObj.fileUrl">
        <p>deviceId：{{ parmasObj.deviceId }}</p>
        <p>address：{{ parmasObj.address }}</p>
        <p>fileUrl：{{ parmasObj.fileUrl }}</p>
        <p>lng：{{ parmasObj.lng }}</p>
        <p>lat：{{ parmasObj.lat }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
export default {
  components: {},
  props: {},
  data() {
    return {
        baseUrl:window.cfg.tianjinUrl,
      parmasObj: {
        deviceId: "",
        fileUrl: "",
        address: "",
        lat: "",
        lng: "",
      },
      form: {
        deviceId: "",
        fileUrl: "",
        address: "",
        lat: "",
        lng: "",
      },
      rules: {
        address: [
          { required: true, message: "请输入address", trigger: "blur" },
        ],
        fileUrl: [
          { required: true, message: "请输入fileUrl", trigger: "blur" },
        ],
        lat: [{ required: true, message: "请输入lat", trigger: "blur" }],
        lng: [{ required: true, message: "请输入lng", trigger: "blur" }],
        deviceId: [
          { required: true, message: "请输入deviceId", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    send() {
      this.$refs.form.validate((val) => {
        if (val) {
          this.sendMessage();
        } else {
          return false;
        }
      });
    },
    resetPram() {
      this.parmasObj = {
        deviceId: "",
        fileUrl: "",
        address: "",
        lat: "",
        lng: "",
      };
    },
    sendMessage() {
      let { form,baseUrl } = this;
      let params = {
        fileUrl: form.fileUrl,
        address: form.address,
        lng: form.lng,
        lat: form.lat,
        deviceId: form.deviceId,
        time: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.resetPram();
      $.ajax({
        url: `${baseUrl}/getParmas`,
        type: "POST",
        dataType: "JSON",
        contentType: "application/json",
        data: JSON.stringify(params),
        success: (data) => {
          let result = data;
          if (result.code == 200) {
            this.$message.success("举报成功");
            this.parmasObj = params;
            this.parmasObj.fileUrl = result.message;
          } else {
            this.$message.error(result.message ? result.message : "举报失败");
          }
        },
        error: (err) => {
          console.log(err);
          this.$message.error("异常");
        },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.myDiv {
  .sendBox {
    width: 1000px;
    margin: 0 auto;
    margin-top: 100px;
  }

  .btn_box {
    width: 800px;
    text-align: center;
  }
  .paramsBox p {
    line-height: 30px;
  }
}
</style>